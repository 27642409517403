<template>
  <div class="total">
    <van-nav-bar
        :fixed="true"
        :placeholder="true"
        title="客户地址信息"
        left-text="返回"
        right-text="新增"
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
    />
    <div class="BulkCrmsCusAddressListLs" v-if="this.BulkCrmsCusAddressList.length<=0">
      <el-empty description="暂无地址数据"></el-empty>
    </div>
    <div v-else class="BulkCrmsCusAddressList" v-for="(item,index) in this.BulkCrmsCusAddressList">
      <el-card shadow="hover">
        <el-descriptions class="margin-top" title="客户地址详情" :column="1" size="medium">
          <template slot="extra">
            <el-button type="primary" icon="el-icon-edit" :disabled="item.type" @click="editAddress(index, item)"
                       circle></el-button>
            <el-button type="info" icon="el-icon-circle-plus-outline" :disabled="item.type"
                       @click="addAddressDetail(index, item)"
                       circle></el-button>
            <el-button type="danger" icon="el-icon-delete" :disabled="item.type" @click="deleteAddress(index, item)"
                       circle></el-button>
          </template>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-postcard"></i>
              省
            </template>
            <el-tag size="small">{{ item.province }}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user-solid"></i>
              市
            </template>
            {{ item.city }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-office-building"></i>
              详细地址
            </template>
            {{ item.detail }}
          </el-descriptions-item>
        </el-descriptions>
      </el-card>
    </div>
  </div>
</template>

<script>

export default {
  name: "BulkCrmsCusAddressListLs",
  components: {},
  data() {
    return {
      BulkCrmsCusAddressList: [],
      addressInfo: {
        cusId: '',
      }
    }
  },
  mounted() {
    this.init()
  },
  // beforeRouteEnter(to, from, next) {
  //   alert(1)
  //   console.log(to.name)
  //   console.log(from.name)
  //   if(to.name === from.name){
  //     next()
  //     alert(1)
  //     this.init()
  //   }else{
  //     next()
  //   }
  // },
  methods: {
    init() {
      this.BulkCrmsCusAddressList = []
      this.$ajax.post('/api/ajax/cusAddress/getBulkCrmsLsCusAddressList.json',
          {cusId: this.$route.params.name, crePers: this.$utils.getUser(), id: ""}).then(res => {
        if (res.status == 200) {
          //非本人添加不允许编辑删除
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].crePers == this.$utils.getUser()) {
              res.data[i].type = false;
            } else {
              res.data[i].type = true;
            }
          }
          console.log(res.data)
          this.BulkCrmsCusAddressList = res.data
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('初始化失败！');
      })
    },
    onClickLeft() {
      //返回上一级页面
      this.$router.push({path: '/CustomerListAdd/' + this.$route.params.name})
    },
    onClickRight() {
      //新增客户页面过来的
      this.$router.push({path: '/BulkCrmsCusAddressAddLs/' + this.$route.params.name})
    },
    deleteAddress(index, item) {
      this.$ajax.post('/api/ajax/cusAddress/deleteBulkCrmsLsCusAddress.json',
          {id: item.id}).then(res => {
        if (res.status == 200) {
          this.$message({
            showClose: true,
            message: '删除成功',
            type: 'success'
          });
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('删除失败！');
      })
      setTimeout(() => {
        this.init()
      }, 200);
    },
    addAddressDetail(index, item) {
      sessionStorage.setItem('cusId', item.cusId)
      this.$router.push({path: '/BulkCrmsCusAddressDetailListLs/' + item.id})
    },
    editAddress(index, item) {
      sessionStorage.setItem('cusId', item.cusId)
      this.$router.push({path: '/BulkCrmsCusAddressEditLs/' + item.id})
    },
  }
}
</script>

<style scoped>
.total {
  background: white;
}

.BulkCrmsCusAddressList {
  margin: 5px 5px;
  background: white;
}

.detail {
  padding: 5px;
}

.van-button {
  width: 220px;
  height: 35px;
  border-radius: 5px;
  font-weight: bold;
  font-family: 新宋体;
}

</style>
